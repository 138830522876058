import type {Board} from '@/model/board/types';
import type {BoardItem, BoardItemWithOrder} from '@/model/board_item/types';
import type {BoardItemOrder} from '@/model/board_item_order/types';
import type {Brand} from '@/model/brand/types';
import type {PartialUpdate} from '@/model/common/types';
import type {Deal} from '@/model/deal/types';
import type {Offer} from '@/model/offer/types';
import type {ExtendedOrg, Org, OrgId} from '@/model/org/types';
import type {OrgMember, OrgMembership} from '@/model/org_membership/types';
import type {OrgProduct} from '@/model/org_product/types';
import type {Product} from '@/model/product/types';
import type {ProductMedia} from '@/model/product_media/types';
import type {ProductStyle} from '@/model/product_style/types';
import type {ClientScrapeProductAttempt} from '@/model/scrape_product_attempt/types';
import type {Shipment, ShipmentDetails, ShipmentId, ShipmentIdObj} from '@/model/shipment/types';
import type {ShipmentHistoryItem} from '@/model/shipment_history_item/types';
import type {Vendor} from '@/model/vendor/types';
// CODE-GENERATOR-SLUG imports

const SocketUpdateEventTypes = {
  Org: {
    Created: 'org-created',
    Updated: 'org-updated',
    Deleted: 'org-deleted',
  },
  Board: {
    Created: 'board-created',
    Updated: 'board-updated',
    Published: 'board-published',
    Unpublished: 'board-unpublished',
    Deleted: 'board-deleted',
  },
  BoardItem: {
    Created: 'board-item-created',
    Updated: 'board-item-updated',
    Deleted: 'board-item-deleted',
  },
  BoardItemOrder: {
    Updated: 'board-item-order-updated',
  },
  Product: {
    Created: 'product-created',
    Updated: 'product-updated',
    Deleted: 'product-deleted',
  },
  ScrapeProductAttempt: {
    Created: 'scrape-product-attempt-created',
    Updated: 'scrape-product-attempt-updated',
    Deleted: 'scrape-product-attempt-deleted',
  },
  Offer: {
    Created: 'offer-created',
    Updated: 'offer-updated',
    Deleted: 'offer-deleted',
  },
  ProductStyle: {
    Created: 'product-style-created',
    Updated: 'product-style-updated',
    Deleted: 'product-style-deleted',
  },
  ProductMedia: {
    Created: 'product-media-created',
    Updated: 'product-media-updated',
    Deleted: 'product-media-deleted',
  },
  OrgProduct: {
    Created: 'org-product-created',
    Updated: 'org-product-updated',
    Deleted: 'org-product-deleted',
  },
  OrgMembership: {
    Created: 'org-membership-created',
    Updated: 'org-membership-updated',
    Deleted: 'org-membership-deleted',
  },
  Deal: {
    Created: 'deal-created',
    Updated: 'deal-updated',
    Deleted: 'deal-deleted',
  },
  Shipment: {
    Created: 'shipment-created',
    Updated: 'shipment-updated',
    Deleted: 'shipment-deleted',
  },
  ShipmentDetails: {
    Created: 'shipment-details-created',
    Updated: 'shipment-details-updated',
  },
  ShipmentHistoryItem: {
    Created: 'shipment-history-item-created',
    Updated: 'shipment-history-item-updated',
  },
  // CODE-GENERATOR-SLUG event types
} as const;

export default SocketUpdateEventTypes;

export type SocketUpdateEvent =
  | {
      type: typeof SocketUpdateEventTypes.Org.Created;
      data: ExtendedOrg[];
    }
  | {
      type: typeof SocketUpdateEventTypes.Org.Updated;
      data: Org[];
    }
  | {
      type: typeof SocketUpdateEventTypes.Org.Deleted;
      data: Org[];
    }
  | {
      type: typeof SocketUpdateEventTypes.Board.Created;
      orgId: OrgId;
      data: Board[];
    }
  | {
      type: typeof SocketUpdateEventTypes.Board.Updated;
      orgId: OrgId;
      data: Board[];
    }
  | {
      type: typeof SocketUpdateEventTypes.Board.Published;
      orgId: OrgId;
      data: Board[];
    }
  | {
      type: typeof SocketUpdateEventTypes.Board.Unpublished;
      orgId: OrgId;
      data: Board[];
    }
  | {
      type: typeof SocketUpdateEventTypes.Board.Deleted;
      orgId: OrgId;
      data: Board[];
    }
  | {
      type: typeof SocketUpdateEventTypes.BoardItem.Created;
      orgId: OrgId;
      data: BoardItemWithOrder[];
    }
  | {
      type: typeof SocketUpdateEventTypes.BoardItem.Updated;
      orgId: OrgId;
      data: BoardItem[];
    }
  | {
      type: typeof SocketUpdateEventTypes.BoardItem.Deleted;
      orgId: OrgId;
      data: BoardItem[];
    }
  | {
      type: typeof SocketUpdateEventTypes.Product.Created;
      orgId: OrgId;
      data: Product[];
    }
  | {
      type: typeof SocketUpdateEventTypes.Product.Updated;
      orgId: OrgId;
      data: Product[];
    }
  | {
      type: typeof SocketUpdateEventTypes.Product.Deleted;
      orgId: OrgId;
      data: Product[];
    }
  | {
      type: typeof SocketUpdateEventTypes.ScrapeProductAttempt.Created;
      orgId: OrgId;
      data: ClientScrapeProductAttempt[];
    }
  | {
      type: typeof SocketUpdateEventTypes.ScrapeProductAttempt.Updated;
      orgId: OrgId;
      data: ClientScrapeProductAttempt[];
    }
  | {
      type: typeof SocketUpdateEventTypes.ScrapeProductAttempt.Deleted;
      orgId: OrgId;
      data: ClientScrapeProductAttempt[];
    }
  | {
      type: typeof SocketUpdateEventTypes.Offer.Created;
      data: Offer[];
    }
  | {
      type: typeof SocketUpdateEventTypes.Offer.Updated;
      data: Offer[];
    }
  | {
      type: typeof SocketUpdateEventTypes.Offer.Deleted;
      data: Offer[];
    }
  | {
      type: typeof SocketUpdateEventTypes.ProductStyle.Created;
      data: ProductStyle[];
    }
  | {
      type: typeof SocketUpdateEventTypes.ProductStyle.Updated;
      data: ProductStyle[];
    }
  | {
      type: typeof SocketUpdateEventTypes.ProductStyle.Deleted;
      data: ProductStyle[];
    }
  | {
      type: typeof SocketUpdateEventTypes.ProductMedia.Created;
      data: ProductMedia[];
    }
  | {
      type: typeof SocketUpdateEventTypes.ProductMedia.Updated;
      data: ProductMedia[];
    }
  | {
      type: typeof SocketUpdateEventTypes.ProductMedia.Deleted;
      data: ProductMedia[];
    }
  | {
      type: typeof SocketUpdateEventTypes.OrgProduct.Created;
      orgId: OrgId;
      data: OrgProduct[];
    }
  | {
      type: typeof SocketUpdateEventTypes.OrgProduct.Updated;
      orgId: OrgId;
      data: OrgProduct[];
    }
  | {
      type: typeof SocketUpdateEventTypes.OrgProduct.Deleted;
      orgId: OrgId;
      data: OrgProduct[];
    }
  | {
      type: typeof SocketUpdateEventTypes.OrgMembership.Created;
      orgId: OrgId;
      data: OrgMembership[];
    }
  | {
      type: typeof SocketUpdateEventTypes.OrgMembership.Updated;
      orgId: OrgId;
      data: OrgMembership[];
    }
  | {
      type: typeof SocketUpdateEventTypes.OrgMembership.Deleted;
      orgId: OrgId;
      data: OrgMembership[];
    }
  | {
      type: typeof SocketUpdateEventTypes.Deal.Created;
      orgId: OrgId;
      data: Deal[];
    }
  | {
      type: typeof SocketUpdateEventTypes.Deal.Updated;
      orgId: OrgId;
      data: Deal[];
    }
  | {
      type: typeof SocketUpdateEventTypes.Deal.Deleted;
      orgId: OrgId;
      data: Deal[];
    }
  | {
      type: typeof SocketUpdateEventTypes.Shipment.Created;
      orgId: OrgId;
      data: Shipment[];
    }
  | {
      type: typeof SocketUpdateEventTypes.Shipment.Updated;
      orgId: OrgId;
      data: Shipment[];
    }
  | {
      type: typeof SocketUpdateEventTypes.Shipment.Deleted;
      orgId: OrgId;
      data: ShipmentIdObj[];
    }
  | {
      type: typeof SocketUpdateEventTypes.ShipmentDetails.Created;
      orgId: OrgId;
      data: ShipmentDetails[];
    }
  | {
      type: typeof SocketUpdateEventTypes.ShipmentDetails.Updated;
      orgId: OrgId;
      data: ShipmentDetails[];
    }
  | {
      type: typeof SocketUpdateEventTypes.ShipmentHistoryItem.Created;
      orgId: OrgId;
      data: ShipmentHistoryItem[];
    }
  | {
      type: typeof SocketUpdateEventTypes.ShipmentHistoryItem.Updated;
      orgId: OrgId;
      data: ShipmentHistoryItem[];
    }
  // CODE-GENERATOR-SLUG actual types
  | {
      type: typeof SocketUpdateEventTypes.BoardItemOrder.Updated;
      orgId: OrgId;
      data: (Pick<BoardItemOrder, 'boardItemId' | 'boardItemType'> & {
        before: Pick<BoardItemOrder, 'order' | 'parentBoardItemId'>;
        after: Pick<BoardItemOrder, 'order' | 'parentBoardItemId'>;
      })[];
    };

export type SocketUpdateEventWithOrgId = Extract<SocketUpdateEvent, {orgId: OrgId}>;

export type SocketUpdateEventWithoutOrgId = Exclude<SocketUpdateEvent, {orgId: OrgId}>;
